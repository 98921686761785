import ChooseCard from "../Child/choosecard";
import data from "../../content/home.json";
import {Link} from "react-router-dom";


function ChooseUsArea () {
return (
    <section className="choose-area sub-bg pt-120 pb-120">
        {/*<div className="choose__video-btn">*/}
        {/*    {*/}
        {/*        data.data.choose.map((data,i) => (*/}
        {/*    <div className="video-btn video-pulse wow fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">*/}
        {/*        <Link className="video-popup" to={data.youtube}><i className="fa-solid fa-play" /></Link>*/}
        {/*    </div>*/}
        {/*        ))*/}
        {/*    }*/}
        {/*</div>*/}
        <div className="choose__shape-right1 wow slideInRight d-none d-lg-block" data-wow-delay="200ms" data-wow-duration="1500ms">
            <img src="assets/images/shape/choose-shape-right.png" alt="shape" />
        </div>
        <div className="choose__shape-right2 wow slideInRight d-none d-lg-block" data-wow-delay="200ms" data-wow-duration="1000ms">
            <img src="assets/images/shape/choose-shape-right2.png" alt="shape" />
        </div>
        <div className="choose__shape-left sway__animation">
            <img src="assets/images/shape/choose-shape-left.png" alt="shape" />
        </div>
        <div className="container">
            <div className="row g-4">
                <div className="col-lg-6 d-block d-lg-none">
                    {
                        data.data.choose.map((data,i) => (
                    <div key={i} className="image">
                        <img src={data.img} alt="image" />
                    </div>
                        ))
                    }
                </div>
                <div className="col-lg-6">
                    {
                        data.data.choose.map((data,i) => (
                    <div key={i} className="section-header mb-40">
                        <h5 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <svg className="me-1" width={20} height={12} viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.75" y="0.75" width="18.5" height="10.5" rx="5.25" stroke="#3C72FC" strokeWidth="1.5" />
                                <mask id="path-2-inside-1_682_455" fill="white">
                                    <path d="M3 6C3 3.79086 4.79086 2 7 2H13C15.2091 2 17 3.79086 17 6C17 8.20914 15.2091 10 13 10H7C4.79086 10 3 8.20914 3 6Z" />
                                </mask>
                                <path d="M3 6C3 2.96243 5.46243 0.5 8.5 0.5H11.5C14.5376 0.5 17 2.96243 17 6C17 4.61929 15.2091 3.5 13 3.5H7C4.79086 3.5 3 4.61929 3 6ZM17 6C17 9.03757 14.5376 11.5 11.5 11.5H8.5C5.46243 11.5 3 9.03757 3 6C3 7.38071 4.79086 8.5 7 8.5H13C15.2091 8.5 17 7.38071 17 6ZM3 10V2V10ZM17 2V10V2Z" fill="#3C72FC" mask="url(#path-2-inside-1_682_455)" />
                            </svg>
                            {data.title}
                        </h5>
                        <h2 className="wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">{data.headline}</h2>
                    </div>
                    ))
                    }
                    <>
                    <ChooseCard/>
                    </>
                    <>
                        {
                            data.data.choose[0].choosebar1.map((data,i) => (
                    <div key={i} className="progress-area wow fadeInDown mt-40" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div className="progress__title mb-10">
                            <h5>{data.title}</h5>
                            <span><span className="progress-count">{data.count}</span>%</span>
                        </div>
                        <div className="progress">
                            <div className="progress-bar wow slideInLeft" data-wow-duration=".86s" role="progressbar" style={{width:'100%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                            </div>
                        </div>
                    </div>
                            ))
                        }
                    </>
                    <>
                        {
                            data.data.choose[0].choosebar2.map((data,i) => (

                    <div key={i} className="progress-area wow fadeInDown mt-20" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div className="progress__title mb-10">
                            <h5>{data.title}</h5>
                            <span><span className="progress-count">100</span>%</span>
                        </div>
                        <div className="progress">
                            <div className="progress-bar wow slideInLeft" data-wow-duration=".95s" role="progressbar" style={{width:"100%"}} aria-valuenow={95} aria-valuemin={0} aria-valuemax={100}>
                            </div>
                        </div>
                    </div>
                            ))
                        }
                            </>
                            <>
                        {
                            data.data.choose[0].choosebar3.map((data,i) => (
                    <div key={i} className="progress-area wow fadeInDown mt-20" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div className="progress__title mb-10">
                            <h5>{data.title}</h5>
                            <span><span className="progress-count">{data.count}</span>%</span>
                        </div>
                        <div className="progress">
                            <div className="progress-bar wow slideInLeft" data-wow-duration=".95s" role="progressbar" style={{width:"100%"}} aria-valuenow={95} aria-valuemin={0} aria-valuemax={100}>
                            </div>
                        </div>
                    </div>
                            ))
                        }
                            </>
                </div>
                <div className="col-lg-6 d-none d-lg-block">
                    {
                        data.data.choose.map((data,i) => (
                    <div key={i} className="choose__image image">
                        <img src={data.img}alt="image" />
                    </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </section>
)
};
export default ChooseUsArea;