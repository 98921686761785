import data from "../../content/clients.json";
import React from 'react'
import {Link} from "react-router-dom";

function ClientCard () {
return (
    <>
        {
            data.data.map((data,i) => (
            <div key={i} className="col-xl-4 col-lg-6 col-md-6">
                <Link target="_blank" to={data.link}>
                <div className="case__item">
                    <div className="image case__image">
                        <img src={data.img} alt="image" loading="lazy" />
                    </div>
                    <div className="case__content">
                        <span className="primary-color sm-font">{data.title}</span>
                        <h3><Link target="_blank" to={data.link} className="text-white primary-hover">{data.name}</Link></h3>
                    </div>

                </div>
                </Link>
            </div>
            ))
        }
    </>
);
};
export default ClientCard;