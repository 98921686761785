import MainSliderArea from '../../component/home/mainSliderArea';
import BrandsArea from "../../component/home/brandsArea";
import AboutArea from "../../component/home/aboutArea";
import ServicesArea from "../../component/home/servicesArea";
import ChooseUsArea from "../../component/home/chooseUsArea";
import FaqArea from "../../component/home/faqArea";
import CaseArea from "../../component/home/caseArea";
import TestmonialArea from "../../component/home/testmonialArea";
import AtestmonialArea from "../../component/about/atestmonialArea";
function Index() {
    return (
        <>
            <MainSliderArea/>
            <BrandsArea/>
            <AboutArea/>
            <ServicesArea/>
            <ChooseUsArea/>
            <AtestmonialArea/>
            <FaqArea/>
            <CaseArea/>
            <TestmonialArea/>
        </>
    );
}

export default Index;