import data from "../../content/home.json";
import React from 'react'
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination, Navigation} from "swiper/modules";

function MainSliderArea () {
return (
    <>
    <section className="banner-two-area secondary-bg ">
        <div className="banner-two__line">
            <img className="left-shape sway_Y__animation" src="assets/images/banner/banner-line.png" alt="shape"/>
        </div>
        <div className="swiper banner__slider">
            <Swiper
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                autoplay={{delay:7000, pauseOnMouseEnter: false}}

            >
                {
                    data.data.slider.map((data,i) => (
                <SwiperSlide key={i}>
                    <div className="banner-two__line-left" data-animation="slideInLeft" data-duration="3s"
                         data-delay=".3s">
                        <img src="assets/images/banner/banner-two-left-line.png" alt="shape"/>
                    </div>
                    {/*<div className="banner-two__shape2" data-animation="slideInRight" data-duration="2s"*/}
                    {/*     data-delay=".3s">*/}
                    {/*    <img src="assets/images/banner/banner-two-solid-right-down.png" alt="shape"/>*/}
                    {/*</div>*/}
                    {/*<div className="banner-two__shape1" data-animation="slideInRight" data-duration="2s"*/}
                    {/*     data-delay=".5s">*/}
                    {/*    <img src="assets/images/banner/banner-two-solid-right-up.png" alt="shape"/>*/}
                    {/*</div>*/}
                    <div className="banner-two__right-shape wow slideInRight" data-wow-delay="200ms"
                         data-wow-duration="1500ms">
                        <img className="right-shape sway_Y__animation" src="assets/images/banner/banner-two-right-shape.png"
                             alt="shape"/>
                    </div>
                    <div className="banner-two__circle-solid">
                        <img className="animation__rotate" src="assets/images/banner/banner-two-circle-solid.png"
                             alt="shape"/>
                    </div>
                    <div className="banner-two__circle-regular">
                        <img className="animation__rotateY" src="assets/images/banner/banner-two-circle-regular.png"
                             alt="shape"/>
                    </div>
                    <div>
                        <img className="slide-bg" src={data.img} alt="shape"/>
                    </div>
                    <div className="container">
                        <div className="banner-two__content text-center">
                            <h4 className="text-white mb-20">
                                {data.title}
                            </h4>
                            <h1 className="text-white">
                                {data.headline}
                            </h1>
                            <p className="mt-20">
                                {data.description}
                            </p>
                            <Link to={data.link} className="btn-one mt-50">Explore
                                More <i className="fa-regular fa-arrow-right-long"/></Link>
                        </div>
                    </div>
                </SwiperSlide>
                ))
                }
            </Swiper>

        </div>
        <div className="banner__dot-wrp banner-two__dot-wrp">
            <div className="dot-light banner__dot"/>
        </div>
    </section>
    </>
)
};
export default MainSliderArea;