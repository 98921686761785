import data from "../../content/about.json";
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay} from "swiper/modules";


function Partner ({setSwiper, fromHomepage}) {
    return (
        <div className="swiper testimonial-two__slider">
            <Swiper
                breakpoints={{
                    320: {
                        // width: 476,
                        slidesPerView: 1,
                    },
                    600: {
                        // width: 476,
                        slidesPerView: 1,
                    },
                    900: {
                        // width: 768,
                        slidesPerView: fromHomepage?2:1,
                    },
                }}
                modules={[Autoplay]}
                spaceBetween={20}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                autoplay={{delay:7000, pauseOnMouseEnter: true}}
                onInit= {e => setSwiper (e)}
            >
                {
                    data.partner.map((data,i) => (
                    <SwiperSlide key={i}>
                                    <div className="section-header mb-80">
                                        <h5 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                                            <img className="me-1" src="assets/images/icon/section-title.png" alt="icon" />
                                            {data.title}
                                        </h5>
                                        <h2 className="wow fadeInUp text-white" data-wow-delay="200ms" data-wow-duration="1500ms">{data.headline}</h2>
                                        <p className="wow fadeInUp text-white-50 mt-30" data-wow-delay="400ms" data-wow-duration="1500ms">
                                            {data.description}</p>
                                    </div>
                        <div className="testimonial-two__item rounded-4 mb-20">
                            <img className="w-100" src={data.img}/>
                        </div>
                    </SwiperSlide>
                ))
            }
            </Swiper>
        </div>
    )
};
export default Partner;