import {Link} from "react-router-dom";
import React from 'react';
import data from "../../content/about.json";
import Tech from "../Child/tech";

function pionerAria () {
return (
    <section className="offer-area secondary-bg pt-120 pb-200">
        <div className="offer__shadow wow fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
            <img src="assets/images/shape/offer-shadow-shape.png" alt="shadow" />
        </div>
        <div className="offer__shape-left">
            <img className="wow fadeInUpBig" data-wow-delay="400ms" data-wow-duration="1500ms" src="assets/images/shape/offer-bg-shape-left.png" alt="shape" />
        </div>
        <div className="offer__shape-right">
            <img className="wow fadeInDownBig" data-wow-delay="400ms" data-wow-duration="1500ms" src="assets/images/shape/offer-bg-shape-right.png" alt="shape" />
        </div>
        <>

        <div className="container">
            <>
            {
                data.technology.map((data,i) => (
            <div key={i} className="d-flex gap-4 flex-wrap align-items-center justify-content-between mb-95">
                <div className="section-header">
                    <h5 className="wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <img className="me-1" src="assets/images/icon/section-title.png" alt="icon" />
                        {data.title}
                    </h5>
                    <h2 className="text-white wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">{data.headline}</h2>
                </div>
                {/*<Link to="/services" className="btn-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">Explore*/}
                {/*    More <i className="fa-regular fa-arrow-right-long" /></Link>*/}
            </div>
                ))
            }
            </>
            <div className="row g-4">
                <Tech/>
            </div>
        </div>
        </>
    </section>
)
};
export default pionerAria;