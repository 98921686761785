import Header from './layout/header';
import Footer from "./layout/footer";
import Loading from "./layout/loading";
import ScrollUp from "./layout/scrollUp";
import SideBar from "./layout/sideBar";
import SearchWrapArea from "./component/home/searchWrapArea";
import Home from "./pages/home/index";
import Contact from "./pages/contact/index";
import Projects from "./pages/projects/index";
import About from "./pages/about/index";
import QuoteArea from "./component/home/quoteArea";
import {Routes, Route} from "react-router-dom";
import Services from "./pages/services";
import { useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import Policy from "./pages/policy";
import Articles from "./pages/articles";
import Catalog from "./pages/catalog";


function App() {
    const {pathname} = useLocation()
    useEffect(() => {
        window.scrollTo({top:0})
    }, [pathname] )
    return (
        <>
        <Loading/>
        <Header/>
        <SideBar/>
        <SearchWrapArea/>
            <Routes>
            <Route path="/" element={ <Home/> } />
                <Route path="/contact" element={ <Contact/> } />
                <Route path="/services" element={ <Services/> } />
                <Route path="/projects" element={ <Projects/> } />
                <Route path="/about" element={ <About/> } />
                <Route path="/policy" element={ <Policy/> } />
                <Route path="/articles" element={ <Articles/> } />
                <Route path="/catalog" element={ <Catalog/> } />
            </Routes>
            <QuoteArea/>
            <Footer/>
            <ScrollUp/>
        </>
    );
}

export default App;