import {Link} from "react-router-dom";
const Footer = () => {
    return (
        <footer className="footer-two-area secondary-bg">
            <div className="footer__shape-regular-left wow slideInLeft" data-wow-delay="00ms"
                 data-wow-duration="1500ms">
                <img src="assets/images/shape/footer-regular-left.png" alt="shape"/>
            </div>
            <div className="footer__shape-solid-left wow slideInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                <img className="sway_Y__animation" src="assets/images/shape/footer-solid-left.png" alt="shape"/>
            </div>
            <div className="footer__shape-solid-right wow slideInRight" data-wow-delay="00ms"
                 data-wow-duration="1500ms">
                <img className="sway_Y__animation" src="assets/images/shape/footer-regular-right.png" alt="shape"/>
            </div>
            <div className="footer__shape-regular-right wow slideInRight" data-wow-delay="200ms"
                 data-wow-duration="1500ms">
                <img src="assets/images/shape/footer-solid-right.png" alt="shape"/>
            </div>
            <div className="footer__shadow-shape">
                <img src="assets/images/shape/footer-shadow-shape.png" alt="shodow"/>
            </div>
            <div className="container">
                <div className="footer__wrp pt-100 pb-100">
                    <div className="footer__item item-big wow fadeInUp" data-wow-delay="00ms"
                         data-wow-duration="1500ms">
                        <Link to="/" className="logo mb-30">
                            <img src="assets/images/logo/logo.svg" alt="image"/>
                        </Link>
                        <p>Riyadh 12121 P.O Box 1212, Kingdom of Saudi Arabia</p>
                        <div className="social-icon">
                            <Link target="_blank" to="http://linkedin.com/company/vfortechnology"><i className="fa-brands fa-linkedin-in"/></Link>
                            <p>/vfortechnology</p>
                        </div>
                    </div>
                    {/*<div className=" footer__item item-sm wow fadeInUp" data-wow-delay="200ms"*/}
                    {/*     data-wow-duration="1500ms">*/}
                    {/*    <h3 className="footer-title">IT Solution</h3>*/}
                    {/*    <ul>*/}
                    {/*        <li><Link to="/services"><i className="fa-regular fa-angles-right me-1"/>Ecommerce</Link>*/}
                    {/*        </li>*/}
                    {/*        <li><Link to="/services"><i className="fa-regular fa-angles-right me-1"/>ERP</Link>*/}
                    {/*        </li>*/}
                    {/*        <li><Link to="/services"><i className="fa-regular fa-angles-right me-1"/>CMS</Link>*/}
                    {/*        </li>*/}
                    {/*        <li><Link to="/services"><i className="fa-regular fa-angles-right me-1"/>V-Catalog</Link>*/}
                    {/*        </li>*/}
                    {/*        <li><Link to="/services"><i className="fa-regular fa-angles-right me-1"/>Digital*/}
                    {/*            Marketing</Link></li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <div className="footer__item item-sm wow fadeInUp" data-wow-delay="400ms"
                         data-wow-duration="1500ms">
                        <h3 className="footer-title">Quick Link</h3>
                        <ul>
                            <li><Link to="/"><i className="fa-regular fa-angles-right me-1"/> Home</Link></li>
                            <li><Link to="/about"><i className="fa-regular fa-angles-right me-1"/> About V4TECH</Link></li>
                            <li><Link to="/services"><i className="fa-regular fa-angles-right me-1"/> Our Services</Link></li>
                            {/*<li><Link to="/projects"><i className="fa-regular fa-angles-right me-1"/> Success Stories</Link></li>*/}
                            <li><Link to="/contact"><i className="fa-regular fa-angles-right me-1"/> Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="footer__item item-big wow fadeInUp" data-wow-delay="600ms"
                         data-wow-duration="1500ms">
                        <h3 className="footer-title">Contact Us</h3>
                        <ul className="footer-contact">
                            <li>
                                <i className="fa-regular fa-clock"/>
                                <div className="info">
                                    <h5>
                                        Opening Hours:
                                    </h5>
                                    <Link to="tel:00966541383093"><p>Call Support</p></Link>
                                </div>
                            </li>
                            <li>
                                <i className="fa-duotone fa-phone"/>
                                <div className="info">
                                    <h5>
                                        Phone Call:
                                    </h5>
                                    <Link to="tel:00966564682252">00966 50 337 4840</Link>
                                </div>
                            </li>
                            <li>
                                <i className="fa-duotone fa-envelope"/>
                                <div className="info">
                                    <h5>
                                        Email Address:
                                    </h5>
                                    <Link to="mailto:info@v4tech.com"><p>info@v4tech.com</p></Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer__copyright">
                <div className="container">
                    <div
                        className="d-flex gap-1 flex-wrap align-items-center justify-content-md-between justify-content-center">
                        <p className="wow fadeInDown" data-wow-delay="00ms" data-wow-duration="1500ms">Copyright © 2024
                            &nbsp;<Link to="/" ><span className="text-white font-weight-bold">V for Technology</span></Link>.&nbsp; All rights reserved.</p>
                        <ul className="d-flex align-items-center gap-4 wow fadeInDown" data-wow-delay="200ms"
                            data-wow-duration="1500ms">
                            <li><Link to="/policy">Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

)
};
export default Footer;
