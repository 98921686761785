import {Link} from "react-router-dom";

function SideBar () {
    return (
        <div id="targetElement" className="sidebar-area sidebar__hide">
            <div className="sidebar__overlay" />
            <Link to="/" className="logo mb-40">
                <img src="assets/images/logo/logo.svg" alt="logo" />
            </Link>
            {/*<div className="sidebar__search mb-30">*/}
            {/*    <input type="text" placeholder="Search..." />*/}
            {/*    <i className="fa-regular fa-magnifying-glass" />*/}
            {/*</div>*/}
            <div className="mobile-menu overflow-hidden" />
            <ul className="info pt-40">
                <h3 className="fa-solid pb-4 text-white">Call us Now</h3>
                <li><i className="fa-solid primary-color fa-location-dot" /> <a href="#0">Almas Plaza, Tahliah Street, Riyadh</a>
                </li>
                <li className="py-2"><i className="fa-solid primary-color fa-phone-volume" /> <Link to="tel:00966503374840">00966 50 337 4840</Link>
                </li>
                <li><i className="fa-solid primary-color fa-paper-plane" /> <Link to="mailto:info@v4tech.com">info@v4tech.com</Link></li>
            </ul>
            <div className="social-icon mt-20">
                {/*<a href="#0"><i className="fa-brands fa-facebook-f" /></a>*/}
                {/*<a href="#0"><i className="fa-brands fa-twitter" /></a>*/}
                <a href="#0"><i className="fa-brands fa-linkedin-in" /></a>
                {/*<a href="#0"><i className="fa-brands fa-youtube" /></a>*/}
            </div>
            <button id="closeButton" className="text-white"><i className="fa-solid fa-xmark" /></button>
        </div>
)
};
export default SideBar;
