import data from "../../content/home.json";
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay} from "swiper/modules";


function TestmonialCard ({setSwiper, fromHomepage}) {
    return (
        <div className="swiper testimonial-two__slider">
            <Swiper
                breakpoints={{
                    320: {
                        // width: 476,
                        slidesPerView: 1,
                    },
                    600: {
                        // width: 476,
                        slidesPerView: 1,
                    },
                    900: {
                        // width: 768,
                        slidesPerView: fromHomepage?2:1,
                    },
                }}
                modules={[Autoplay]}
                spaceBetween={20}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                autoplay={{delay:2000, pauseOnMouseEnter: true}}
                onInit= {e => setSwiper (e)}
            >
            {
                data.data.testmonial[0].testmonialcard.map((data,i) => (
                    <SwiperSlide key={i}>
                        <div className="testimonial-two__item">
                            <h3 >{data.client}</h3>
                            <div className="star mb-10">
                                <i className="fa-sharp fa-solid fa-star" />
                                <i className="fa-sharp fa-solid fa-star" />
                                <i className="fa-sharp fa-solid fa-star" />
                                <i className="fa-sharp fa-solid fa-star" />
                                <i className="fa-sharp fa-solid fa-star" />
                            </div>
                            <p className="mb-30">{data.description}</p>
                            <div className="d-flex align-items-center gap-3">
                                <img src={data.img} alt="image" />
                                <div className="con">
                                    <h4>{data.name}</h4>
                                    <span>{data.title}</span>
                                </div>
                            </div>
                            <svg className="coma" width={50} height={37} viewBox="0 0 50 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0V37L18.75 18.5V0H0ZM31.25 0V37L50 18.5V0H31.25Z" fill="#3C72FC" />
                            </svg>
                        </div>
                    </SwiperSlide>
                ))
            }
            </Swiper>
        </div>
    )
};
export default TestmonialCard;