import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';
import { useState } from 'react'
import Tags from "../../component/Child/tags";
import Creator from "../../component/Child/creator";

function ArticlesForm () {
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };
    let defaultDate = new Date()
    defaultDate.setDate(defaultDate.getDate() + 3)

    const [date, setDate] = useState(defaultDate)

    const onSetDate = (event) => {
        setDate(new Date(event.target.value))
    }
        return (
            <div className="container mt-40">
                <div className="row">
                    <div className="contact__form">
                        <h3>Add New Article</h3>
                        <form method="post" role="form">
                            <div className="mt-4">
                                <div className="form-group col-3">
                                    <label htmlFor="Date">Creation Date</label>
                                    <input type="date" id="date" name="date" value={date.toLocaleDateString('en-CA')} onChange={onSetDate}/>
                                </div>
                            </div>
                            <div className="mt-4">
                                <Tags/>
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="Article Title">Article Title</label>
                                <input type="text" className="form-control" name="title" placeholder="Title" />
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="Article Sub Title">Article Sub Title</label>
                                <textarea type="text" className="form-control" name="subtitle" placeholder="Sub Title" />
                            </div>
                            <div className="form-group mt-4 ">
                                <label htmlFor="Article Content Area">Article Content Area</label>
                                <Editor
                                    apiKey='mxo8v67j4a32tzzo8b4vladccmqjjhv4wavczja7tbwxbl4r'
                                    onInit={(_evt, editor) => editorRef.current = editor}
                                    // initialValue="<p>This is the initial content of the editor.</p>"
                                    init={{
                                        height: 400,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>
                            <div className="mt-4">
                                <Creator/>
                            </div>
                            <div className="form-group mt-40">
                                <label> Images Upload </label>
                                <div className="input-group">
                                      <span className="input-group-btn me-3">
                                        <span className="btn btn-primary btn-file">
                                          Main Image <input type="file" name="bimg"/>
                                        </span>
                                      </span>
                                    <span className="input-group-btn">
                                        <span className="btn btn-primary btn-file">
                                          Second Images <input type="file" name="bimgs" multiple />
                                        </span>
                                      </span>
                                </div>

                            </div>
                            <div className="btn-two mt-40 mb-40">
                                <span className="btn-circle" />
                                <input className="btn-one" type="submit" value="Submit"></input>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
export default ArticlesForm;