import data from "../../content/home.json";
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import {Autoplay} from "swiper/modules";

function BrandsArea () {
return (
    <div className="brand-area">
        <div className="container">
            <div className="brand__wrp">
                <div className="brand__shape">
                    <img src="assets/images/shape/brand-shape.png" alt="" />
                </div>
                <div>
                    <Swiper
                        breakpoints={{
                            320: {
                                // width: 320,
                                slidesPerView: 2,
                            },
                            476: {
                                // width: 476,
                                slidesPerView: 3,
                            },
                            768: {
                                // width: 768,
                                slidesPerView: 4,
                            },
                            900: {
                                // width: 768,
                                slidesPerView: 5,
                            },

                        }}
                        modules={[Autoplay]}
                        spaceBetween={10}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        loop={true}
                        autoplay={{delay:2000}}
                    >
                        {
                            data.data.brands.map((data,i) => (
                        <SwiperSlide key={i}>
                            <div className="brand__image image">
                                <img src={data.img} alt="image" />
                            </div>
                        </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    </div>
)
};
export default BrandsArea;