import BannerArea from "../../component/services/bannerArea";
import ServicesArea from "../../component/services/servicesArea";

function Index() {
    return (
        <>
            <BannerArea/>
            <ServicesArea/>
        </>
    );
}

export default Index;