import BannerArea from "../../component/contact/bannerArea";
import ContactArea from "../../component/contact/contactArea";
import MapArea from "../../component/contact/mapArea";

function Index() {
    return (
        <>
            <BannerArea/>
            <ContactArea/>
            <MapArea/>
        </>
    );
}

export default Index;