import data from "../../content/home.json";
import React from 'react'
import {Link} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay} from "swiper/modules";


function ErpFeaturedCard ({setSwiper2}) {
    return (
        <div className="swiper case-two__slider1">
            <Swiper
                breakpoints={{
                    300: {
                        // width: 300,
                        slidesPerView: 1.1,
                    },
                    768: {
                        // width: 768,
                        slidesPerView: 4,
                    },
                }}
                modules={[Autoplay]}
                spaceBetween={10}

                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                autoplay={{delay:2000, pauseOnMouseEnter: true}}
                onInit= {e => setSwiper2 (e)}
            >
            {
                data.data.erpfeatured[0].erpfeaturedcard.map((data,i) => (
                <SwiperSlide key={i}>
                {/*<Link to={data.link}>*/}
                {/*<div className="case-two__item">*/}
                {/*    <div className="image case-two__image">*/}
                {/*        <img className="" src={data.img} alt="image" />*/}
                {/*    </div>*/}
                {/*    <div className="case-two__content">*/}
                {/*        <span>{data.title}</span>*/}
                {/*        <h4><p className="text-white">{data.name}</p></h4>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*   </Link>*/}

                        <div className="case-two__item">
                            <div className="image case-two__image">
                                <img className="" src={data.img} alt="image" />
                            </div>
                            <div className="case-two__content">
                                <span>{data.title}</span>
                                <h4><p className="text-white">{data.name}</p></h4>
                            </div>
                        </div>
                </SwiperSlide>
                ))
            }
            </Swiper>
        </div>
    )
};
export default ErpFeaturedCard;