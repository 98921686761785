import data from "../../content/contact.json";
import React from "react";

function MapArea () {
return (
    <>
        {
            data.map.map((data,i) => (
    <div key={i} className="contact__map mb-">
        <iframe src={data.link} width={600} height={400} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
    </div>
                ))
        }
    </>
)
};
export default MapArea;