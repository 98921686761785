import data from "../../content/articles.json";
import React from 'react'


function Creator () {
    return (
        <div className="form-group col">
        <label htmlFor="tag">Select Created By</label>
        <select className="nice-select" name="tag" id="tag" placeholder="Select Tag">
        {
            data.createdby.map((data,i) => (
                    <option key={i}>{data.name}</option>
            ))
        }
        </select>
        </div>
    )
};
export default Creator;