import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay} from "swiper/modules";
import {Link} from "react-router-dom";
import data from "../../content/home.json";

function ServSwiper ({setSwiper}) {
    return (
<>
        <Swiper
            breakpoints={{
                300: {
                    // width: 576,
                    slidesPerView: 1,
                },
                768: {
                    // width: 768,
                    slidesPerView: 3,
                },
            }}
            modules={[Autoplay]}
            spaceBetween={30}
            slidesPerView={3}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            loop={true}
            autoplay={{delay:4000, pauseOnMouseEnter: true}}
            onInit= {e => setSwiper (e)}
        >
            {
                data.data.services[0].servicecard.map((data,i) => (
            <SwiperSlide key={i} >
                        <div className="service-two__item">
                            <div className="image">
                                <img src={data.img} alt="image" />
                            </div>
                            <div className="service-two__content">
                                <div className="icon">
                                    <img src={data.icon} alt="icon" />
                                </div>
                                <div className="shape"><img src="assets/images/shape/service-two-item-shape.png" alt="shape" /></div>
                                {/*<h4><Link to={data.link} className="primary-hover">{data.title}</Link></h4>*/}
                                <h4>{data.title}</h4>
                                <p>{data.description}</p>
                                {/*<Link className="read-more-btn" to={data.link}>Read More <i className="fa-regular fa-arrow-right-long" /></Link>*/}
                                {/*<Link className="read-more-btn">Read More <i className="fa-regular fa-arrow-right-long" /></Link>*/}
                            </div>
                        </div>
            </SwiperSlide>
                ))
            }
        </Swiper>
</>
    )
};
export default ServSwiper;