import {useRef, useState} from "react";
import data from "../../content/about.json";
import Partner from "./partner";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2';


function TestmonialArea () {
    const [Swiper, setSwiper] = useState({})
    const [name , setName] = useState("");
    const [title , setTitle] = useState("");
    const [mobile , setMobile] = useState("");
    const [email , setEmail] = useState("");
    const [company , setCompany] = useState("");
    const [subject , setSubject] = useState("");
    const [message , setMessage] = useState("");


    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        setName("");
        setTitle("");
        setMobile("");
        setEmail("");
        setCompany("");
        setSubject("");
        setMessage("");

        emailjs
            .sendForm('service_c5vbs4h', 'template_tgvmypi', form.current, {
                publicKey: 'C9RI8zXJEpR30HcJf',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    Swal.fire({
                        title: "Success!",
                        text: "Message sent Successfully!",
                        icon: "success"
                    });
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                    });
                },
            );
    };

return (
    <section className="case-two-area1 secondary-bg pt-120">
        <div className="case-two__bg">
            <img src="assets/images/bg/case-two-bg.png" alt="image" />
        </div>
        <div className="container">
            <div className="row g-4">
                <div className="col-lg-6 wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                    <div className="talk-us__item">
                        <>
                            {
                                data.talk.map((data,i) => (
                        <div key={i} className="section-header mb-30">
                            {/*<h5 className="text-white">*/}
                            {/*    <svg width={28} height={12} viewBox="0 0 28 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*        <rect x="0.75" y="0.75" width="18.5" height="10.5" rx="5.25" stroke="white" strokeWidth="1.5" />*/}
                            {/*        <rect x={8} width={20} height={12} rx={6} fill="white" />*/}
                            {/*    </svg>*/}
                            {/*    {data.title}*/}
                            {/*</h5>*/}
                            <h2 className="text-white">{data.headline}</h2>
                        </div>
                                ))
                            }
                        </>
                        <>
                            {
                                data.talk[0].form.map((data,i) => (
                        <form key={i} action="" ref={form} onSubmit={sendEmail}>
                            <div className="row g-3">
                                <div className="col-sm-6 mt-2">
                                    <label htmlFor="name">{data.name}</label>
                                    <input value={name} name="name" type="name" id="name" placeholder={data.placeholdername} onChange={(e) => setName(e.target.value)} required/>
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label htmlFor="position">{data.position}</label>
                                    <input value={title} name="title" type="text" id="title" placeholder={data.placeholderposition} onChange={(e) => setTitle(e.target.value)} required/>
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label htmlFor="number">{data.mobile}</label>
                                    <input value={mobile} name="mobile" type="mobile" id="mobile" maxlength="10" minLength="10" placeholder={data.placeholdermobile} onChange={(e) => setMobile(e.target.value)} required/>
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label htmlFor="email">{data.email}</label>
                                    <input value={email} name="email" type="email" id="email" placeholder={data.placeholderemail} onChange={(e) => setEmail(e.target.value)} required/>
                                </div>
                                <div className="col-sm-12 mt-2">
                                    <label htmlFor="company">{data.company}</label>
                                    <input value={company} name="company" type="company" id="company" placeholder={data.placeholdercompany} onChange={(e) => setCompany(e.target.value)} required/>
                                </div>
                                <div className="col-sm-12 mt-2">
                                    <label htmlFor="subject">{data.subject}</label>
                                    <input value={subject} name="subject" type="subject" id="subject" placeholder={data.placeholdersubject} onChange={(e) => setSubject(e.target.value)} required/>
                                </div>
                                <div className="col-12 mt-2">
                                    <label htmlFor="massage">{data.message}</label>
                                    <textarea value={message} name="text-area" id="message" placeholder={data.placeholdermessage} onChange={(e) => setMessage(e.target.value)} required/>
                                </div>
                            </div>
                            <button type="submit">{data.action}</button>
                        </form>
                            ))
                            }
                        </>
                    </div>
                </div>
                <div className="col-lg-6 ps-2 ps-lg-5">
                    <Partner setSwiper = {setSwiper}/>
                    <div className="testimonial__arry-btn mt-40 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <button onClick={() => Swiper.slidePrev()} className="arry-prev service__arry-prev"><i className="fa-light fa-chevron-left" /></button>
                        <button onClick={() => Swiper.slideNext()} className="arry-next service__arry-next active"><i className="fa-light fa-chevron-right" /></button>
                    </div>
                </div>
            </div>
        </div>
    </section>
)
};
export default TestmonialArea;