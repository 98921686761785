import data from "../../../content/mega.json";
import React from 'react';
import {Link} from "react-router-dom";

function ServCard () {
    return (
<>
                        {
                            data.mega.map((data,i) => (
                                <div key={i} className="image text-center">
                                    <img src={data.img} alt="image"/>
                                    <div className="btn__group">
                                        {/*<Link className="btn-one" to={data.link}>View Page</Link>*/}
                                    </div>
                                    <h6 className="text-white">{data.name}</h6>
                                </div>
                            ))
                        }
</>
    )
};
export default ServCard;