import ServSwiper from "../Child/servSwiper";
import data from "../../content/home.json";
import {useState} from "react";

function ServicesArea () {
    const [Swiper, setSwiper] = useState({})
return (
    <section className="service-two-area secondary-bg pt-120 pb-120">
        {/*<div className="service-two__shape-left sway_Y__animationY">*/}
        {/*    <img src="assets/images/shape/service-two-shape-left.png" alt="shape" />*/}
        {/*</div>*/}
        <div className="service-two__shape-right sway_Y__animation">
            <img src="assets/images/shape/service-two-shape-right.png" alt="shape" />
        </div>
        <div className="container">
            <div className="d-flex gap-4 flex-wrap align-items-center justify-content-between mb-60">
                {
                    data.data.services.map((data,i) => (
                <div key={i} className="section-header">
                    <h5 className="wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <svg className="me-1" width={20} height={12} viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.75" y="0.750061" width="18.5" height="10.5" rx="5.25" stroke="#3C72FC" strokeWidth="1.5" />
                            <mask id="path-2-inside-1_670_477" fill="white">
                                <path d="M3 6.00006C3 3.79092 4.79086 2.00006 7 2.00006H13C15.2091 2.00006 17 3.79092 17 6.00006C17 8.2092 15.2091 10.0001 13 10.0001H7C4.79086 10.0001 3 8.2092 3 6.00006Z" />
                            </mask>
                            <path d="M3 6.00006C3 2.96249 5.46243 0.500061 8.5 0.500061H11.5C14.5376 0.500061 17 2.96249 17 6.00006C17 4.61935 15.2091 3.50006 13 3.50006H7C4.79086 3.50006 3 4.61935 3 6.00006ZM17 6.00006C17 9.03763 14.5376 11.5001 11.5 11.5001H8.5C5.46243 11.5001 3 9.03763 3 6.00006C3 7.38077 4.79086 8.50006 7 8.50006H13C15.2091 8.50006 17 7.38077 17 6.00006ZM3 10.0001V2.00006V10.0001ZM17 2.00006V10.0001V2.00006Z" fill="#3C72FC" mask="url(#path-2-inside-1_670_477)" />
                        </svg>
                        {data.title}
                    </h5>
                    <h2 className="text-white wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                        {data.headline}</h2>
                </div>
                    ))
                }
                <div className="arry-btn  d-flex gap-3 wow fadeInUp z-index-10" data-wow-delay="200ms" data-wow-duration="1500ms">
                    <button onClick={() => Swiper.slidePrev()} className="arry-prev service__arry-prev"><i className="fa-light fa-chevron-left" /></button>
                    <button onClick={() => Swiper.slideNext()} className="arry-next service__arry-next active"><i className="fa-light fa-chevron-right" /></button>
                </div>
            </div>
            <div>
                <ServSwiper setSwiper = {setSwiper}/>
            </div>
        </div>
    </section>
)
};
export default ServicesArea;