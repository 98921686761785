import FeaturedCard from "../Child/featuredCard";
import ErpFeaturedCard from "../Child/erpFeaturedCard";
import data from "../../content/home.json";
import {useState} from "react";

function CaseArea () {
    const [Swiper1, setSwiper1] = useState({})
    const [Swiper2, setSwiper2] = useState({})
return (
    <section className="case-two-area secondary-bg pt-120">
        <div className="case-two__bg">
            <img src="assets/images/bg/case-two-bg.png" alt="image" />
        </div>
        <div>
        <div className="container">
            <div className="section-header">
            {
                data.data.featured.map((data,i) => (
            <div key={i} className="w-full h-100px mb-10">
                <h5 className="wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                    <svg className="me-1" width={20} height={12} viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.75" y="0.747803" width="18.5" height="10.5" rx="5.25" stroke="#3C72FC" strokeWidth="1.5" />
                        <mask id="path-2-inside-1_1120_297" fill="white">
                            <path d="M3 5.9978C3 3.78866 4.79086 1.9978 7 1.9978H13C15.2091 1.9978 17 3.78866 17 5.9978C17 8.20694 15.2091 9.9978 13 9.9978H7C4.79086 9.9978 3 8.20694 3 5.9978Z" />
                        </mask>
                        <path d="M3 5.9978C3 2.96024 5.46243 0.497803 8.5 0.497803H11.5C14.5376 0.497803 17 2.96024 17 5.9978C17 4.61709 15.2091 3.4978 13 3.4978H7C4.79086 3.4978 3 4.61709 3 5.9978ZM17 5.9978C17 9.03537 14.5376 11.4978 11.5 11.4978H8.5C5.46243 11.4978 3 9.03537 3 5.9978C3 7.37851 4.79086 8.4978 7 8.4978H13C15.2091 8.4978 17 7.37851 17 5.9978ZM3 9.9978V1.9978V9.9978ZM17 1.9978V9.9978V1.9978Z" fill="#3C72FC" mask="url(#path-2-inside-1_1120_297)" />
                    </svg>
                    {data.title}
                </h5>
            </div>
                ))
            }
            </div>

            {
                data.data.featured.map((data,i) => (
            <div key={i} className="d-flex gap-4 flex-wrap align-items-center justify-content-between mb-60">
                <div className="section-header">
                    <h2 className="text-white wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                        {data.headline}</h2>
                </div>
                <div className="arry-btn  d-flex gap-3 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                    <button onClick={() => Swiper1.slidePrev()} className="arry-prev service__arry-prev"><i className="fa-light fa-chevron-left" /></button>
                    <button onClick={() => Swiper1.slideNext()} className="arry-next service__arry-next active"><i className="fa-light fa-chevron-right" /></button>
                </div>
            </div>
                ))
            }
        </div>
            <div className="case-two__container">
                <FeaturedCard setSwiper1 = {setSwiper1}/>
            </div>
        </div>
        <div className="pt-80">
            <div className="container">
                {
                    data.data.erpfeatured.map((data,i) => (
                        <div key={i} className="d-flex gap-4 flex-wrap align-items-center justify-content-between mb-60">
                            <div className="section-header">
                                <h2 className="text-white wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                                    {data.headline}</h2>
                            </div>

                            <div  className="arry-btn  d-flex gap-3 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <button onClick={() => Swiper2.slidePrev()} className="arry-prev service__arry-prev"><i className="fa-light fa-chevron-left" /></button>
                                <button onClick={() => Swiper2.slideNext()} className="arry-next service__arry-next active"><i className="fa-light fa-chevron-right" /></button>
                            </div>
                        </div>
                    ))
                }

            </div>
            <div className="case-two__container">
                <ErpFeaturedCard setSwiper2 = {setSwiper2}/>
            </div>
        </div>
    </section>
)
};
export default CaseArea;