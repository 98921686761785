import data from "../../../content/home.json";
import React from 'react';
import {Link} from "react-router-dom";

function ServCard () {
    return (
<>
                        {
                            data.data.services[0].servicecard.map((data,i) => (
                            <div key={i} className="col-lg-4 col-md-6">
                            <div className="service-two__item">
                            <div className="image">
                            <img src={data.img} alt="image" />
                            </div>
                            <div className="service-two__content">
                            <div className="icon">
                            <img src={data.icon} alt="icon" />
                            </div>
                            <div className="shape"><img src="assets/images/shape/service-two-item-shape.png" alt="shape" /></div>
                            <h4><Link to={data.link} className="primary-hover">{data.title}</Link></h4>
                            <p>{data.description}</p>
                            {/*<Link className="read-more-btn" to={data.link}>Read More <i className="fa-regular fa-arrow-right-long" /></Link>*/}
                            </div>
                            </div>
                            </div>
                            ))
                        }
</>
    )
};
export default ServCard;