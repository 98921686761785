import ClientCard from "../Child/clientCard";

const ClientArea = () => {
return (
    <section className="case-area pt-120 pb-120">
        <div className="container">
            <div className="row g-4">
                <ClientCard/>
            </div>
        </div>
    </section>
);
};
export default ClientArea;