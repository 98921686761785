import ServSwiper from "../Child/servSwiper";
import {Link} from "react-router-dom";
import {useState} from "react";
import data from "../../content/home.json";

function CaseArea () {
    const [Swiper, setSwiper] = useState({})
return (
    <section className="case-area pt-120 pb-120">
        <div className="container">
            {
                data.data.services.map((data,i) => (
            <div className="d-flex flex-wrap gap-4 align-items-center justify-content-between mb-60">
                <div className="section-header">
                    <h5 className="wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <img className="me-1" src="assets/images/icon/section-title.png" alt="icon" />
                        {data.title}
                    </h5>
                    <h2 className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">{data.headline}</h2>
                </div>
                <Link to="/about" className="btn-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">Show More
                    <i className="fa-regular fa-arrow-right-long" /></Link>
            </div>
                ))
            }
        </div>
        <div className="container">
        <div>
            <ServSwiper setSwiper = {setSwiper}/>
        </div>
        <div className="mt-60 text-center wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
            <div className="dot case__dot" />
        </div>
        </div>
    </section>
)
};
export default CaseArea;