
function Index() {
    return (
        <iframe
            src="https://flayer-dashboard.voucherek.com/list-page/flayer/66dd50efc27423d4f5b6c0a5?bgColor=fc2779"
            height="900"
            width="100%"
            title="catalogue preview"
        >
        </iframe>
    );
}

export default Index;