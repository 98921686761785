import {Link} from "react-router-dom";
import BannerArea from "../../component/policy/bannerArea";

function Index() {
    return (

        <div className="policy">
            <div className="entry-content">
                <BannerArea/>
                <div className="content_section content_section_marker">
                    <div className="container ">
                        <section className="text_content image_shadow_ inside_content_section mt-40 ">
                            <h3>1. OUR DATA COLLECTION PRACTICES</h3>
                            <ul>
                                <li aria-level={1}>V4TECH (collectively “V4TECH” or “we”) is an international software development company. Together with our subsidiaries, we have written this Privacy Policy (“Policy”) to explain our online data protection practices. This Policy explains what information we will collect about you when you visit our websites and subsidiary sites (collectively “Websites”) at <Link href="https://www.v4tech.com/">www.v4tech.com</Link>.</li>
                                <li aria-level={1}>We also collect your data when you send an email, fill in our contact form or apply for employment through our Website. This Policy outlines how we use to collect the information, how we use it, and how you, the data subject, can control our use of this data. We also explain our use of cookies and any limitations on our liability.</li>
                                <li aria-level={1}>Before you use our Website, we encourage you to familiarize yourself with our Policy and its details. It outlines specifically the extent of our use of your personal data. Our Websites are not suitable for children under 16, and we do not market to them. We do not knowingly collect personal information from children under 16.</li>
                                <li aria-level={1}>We include our Website Terms of Use at the end of this Policy. This Policy is part of our Terms of Use. Please note that we will update this policy and our Terms periodically, and we urge you to read it regularly for any updates.</li>
                                <li aria-level={1}>If you have questions about our Policy, please contact us at <Link to="mailto:info@v4tech.com">info@v4tech.com</Link></li>
                            </ul>
                        </section>
                    </div>
                </div>
                <div className="content_section content_section_marker">
                    <div className="container ">
                        <section className="text_content image_shadow_ inside_content_section mt-40"><h3>2. HOW WE USE YOUR DATA</h3>
                            <ul>
                                <li aria-level={1}>We follow the terms and dictates of the EU General Data Protection Regulation (GDPR), which is an international protocol for the safe collection and use of online data. V4TECH controls and processes all personal data collected from this Website and other sources. The term controller means we have sole responsibility and discretion for our use of your personal information.</li>
                                <li aria-level={1}>We use third-party services for recruitment and other purposes. When you apply for employment, send us your resume, contact us regarding employment, or otherwise engage with career-related services, you agree we will collect personal information about you. You also agree to our transfer and sharing of this data to our third-party subsidiaries. In these cases, we remain the processor of your data.</li>
                            </ul>
                        </section>
                    </div>
                </div>
                <div className="content_section content_section_marker">
                    <div className="container ">
                        <section className="text_content image_shadow_ inside_content_section mt-40 "><h3>3. TYPES OF INFORMATION WE COLLECT</h3>
                            <p>We only collect information that makes site navigation possible or allows you to interact with our subsidiary websites.</p>
                            <h4>How and When We Collect Information</h4>
                            <ul>
                                <li aria-level={1}>When you contact us through email, the contact form, or any contact feature on our Website, you agree to provide your name, phone number, email address, legal address, and other personal data.</li>
                                <li aria-level={1}>If you apply for a job, we will collect your full name, work history, location, examples of past work, online portfolios, phone number, location, and other information you choose to send us. We may also keep information about you when you refer a candidate for employment or contact us on behalf of a recruiting agency.</li>
                                <li aria-level={1}>As an international company, V4TECH sometimes collects information from unrelated websites around the world. These might include job boards, social media accounts, work referrals, online reviews, and other sources. If you publish your work history or work portfolio online, you often agree to provide personal information that may be shared with us and others. Before you sign up with online accounts or job boards, read their privacy policies to understand what information they may collect and share.</li>
                            </ul>
                            <h4 />
                            <h4>How We Collect Data from our Websites</h4>
                            <p>When you visit our Websites, you agree to the following types of collection activities:</p>
                            <ul>
                                <li aria-level={1}>Information about the device you’re using, including make, model, and browser type.</li>
                                <li aria-level={1}>Data about how you use our Website, including your IP address, geographical location, referral link, page views, and Website navigation paths.</li>
                                <li aria-level={1}>Cookies. V4TECH uses cookies. An explanation about the type of cookies we use is in section 7 of this Policy.</li>
                            </ul>
                        </section>
                    </div>
                </div>
                <div className="content_section content_section_marker">
                    <div className="container ">
                        <section className="text_content image_shadow_ inside_content_section mt-40 "><h3>4. WHEN AND HOW WE USE YOUR DATA</h3>
                            <p>We only use your personal data in accordance with local and international law. Our use depends on a legitimate legal reason for using all personal data we have collected about you.</p>
                            <p>We only use your personal data where we have a legal basis to do so. We will use your personal data when you have expressly consented to our use. We also share information when it’s necessary to comply with audits, investigations, and other legal situations.</p>
                            <p>When we collect information about you from our Website, third parties, or outside sources, we will use it only in accordance with the limitations of the GDPR regarding legitimate business use, recruitment, legitimate business interests, marketing purposes, investor relations, and security protocols.</p>
                            <h4>Legitimate Business Use Under the GDPR</h4>
                            <p>When we collect data about you from our Websites or other sources, we may use your information to conduct business. This includes using your personal information to schedule business meetings, organize travel, host online meetings, or otherwise engage in business collaborations.</p>
                            <p>When you contact us directly through our Websites or other means, we may record and store the information on our Websites or affiliated sites.</p>
                            <h4>&nbsp;Legitimate Marketing Use Under the GDPR</h4>
                            <ul>
                                <li aria-level={1}>We use cookies to track your navigation of our Websites, improve our marketing efforts, conduct research on our Website visitors, enhance our corporate communications, assess our outreach and social media interactions, make business plans and conduct market analytics. We may use this data to change our Website design and analyze our business practices.</li>
                                <li aria-level={1}>When you subscribe to newsletters or other materials we publish, you agree to let us contact you regarding changes to our business, updates to our services, new marketing materials, and business announcements.</li>
                            </ul>
                            <h4 />
                            <h4>Legitimate Recruitment Use Under the GDPR</h4>
                            <ul>
                                <li aria-level={1}>When you apply for a contracted or full-time position with us, you agree we may retain your personal data to improve recruitment-related communications and streamline our onboarding process. When you submit your resume or direct us to your portfolio, you agree to share this data with us.</li>
                                <li aria-level={1}>Sometimes, we will share your data with clients to enhance our business engagement efforts. In these cases, we will show your personal information, resume, and portfolio to clients to highlight the skills and experience of our team members. We will do this only when we receive a specific request from our clients.</li>
                                <li aria-level={1}>We have an international clientele, and we often receive requests about our team member’s backgrounds from these clients. In response to these requests, we may share your information to ensure you have the clearance to view a client’s protected information and business secrets. In these cases, we may request further information in the forms of background checks, work permits, tax status, citizenship status, and other checks. We may do the same to meet regulatory and legal obligations regarding our employment practices.</li>
                            </ul>
                            <h4 />
                            <h4>Legitimate Business Use Under the GDPR</h4>
                            <ul>
                                <li aria-level={1}>If you are employed by one of our clients, vendors, or business partners, we may use your data for business documentation. This includes production and signing of contracts, proposals, business plans, invoices, business accounts, and other documentation.</li>
                                <li aria-level={1}>We may present your personal background, experience, and portfolio to business contacts as part of our efforts to promote our business, ensure the quality of our staff and contractors, and comply with the legal requirements of regulators.</li>
                                <li aria-level={1}>We will share your personal data when required to meet standards for data protection, fraud protection, and efforts to regulate money laundering and corruption.</li>
                            </ul>
                            <h4 />
                            <h4>Corporate and Investor Relations Under the GDPR</h4>
                            <ul>
                                <li aria-level={1}>If you are an executive of V4TECH or one of our affiliates, we may use your personal information to maintain and update our corporate records, present information about our executives to potential clients, and meet the obligations of auditors and investigators.</li>
                                <li aria-level={1}>We may use your information to support you and protect your rights in legal cases, audits, or other regulatory matters.</li>
                                <li aria-level={1}>We may use your information to arrange corporate travel, plan meetings, produce meeting agendas, create presentations, and write corporate reports.</li>
                                <li aria-level={1}>V4TECH will use your personal information to meet legal obligations related to banking, payroll, legal agencies, and other financial and legal institutions. We may also use your information to produce annual reports, open corporate banking accounts, and meet all regulatory and legal requirements.</li>
                            </ul>
                            <h4 />
                            <h4>Legitimate Security Interests Under the GDPR</h4>
                            <ul>
                                <li aria-level={1}>We use your information to ensure our Website is free from fraud, preserve our rights and interests, and protect our intellectual property rights. When you visit our Websites, you agree to allow our use of your information for these purposes.</li>
                                <li aria-level={1}>We may use personal information to detect and prevent fraudulent or illegal use of our Websites.</li>
                            </ul>
                        </section>
                    </div>
                </div>
                <div className="content_section content_section_marker">
                    <div className="container ">
                        <section className="text_content image_shadow_ inside_content_section mt-40 "><h3>5. RETENTION OF YOUR PERSONAL RECORDS</h3>
                            <ul>
                                <li aria-level={1}>This section concerns the retention and processing of your data. The term processing refers to our collection, recording, retention, organization, and storage of your personal data. It also includes our ability to adapt, alter, erase or delete your personal information.</li>
                                <li aria-level={1}>As an international company, we may transmit or transfer your personal information to outside websites and companies. Our transference of your personal data will always comply with laws regarding data protection and transmission.</li>
                                <li aria-level={1}>Our transmissions may extend throughout the European Economic Area, the U.S., and other geographical locations. We limit these transfers and transmissions to situations where they are necessary to comply with the terms of this Policy and all governing bodies of law.</li>
                                <li aria-level={1}>We may disclose your personal data to some of our clients for business engagement or to confirm the high skill levels of our team members. We may also display them to auditors, agencies, supervisory authorities, or other external service providers as required by our contractual obligations.</li>
                                <li aria-level={1}>We may also disclose your personal data if we are required by law to disclose it.</li>
                                <li aria-level={1}>We will share your information when necessary to exercise our legal rights, protect our data, prevent fraud and reduce our risk of liability.</li>
                                <li aria-level={1}>We will share your personal data when required by a criminal investigation, legal proceeding, or other legal obligation.</li>
                            </ul>
                        </section>
                    </div>
                </div>
                <div className="content_section content_section_marker">
                    <div className="container ">
                        <section className="text_content image_shadow_ inside_content_section mt-40 "><h3>6. YOUR RIGHTS AS A DATA SUBJECT</h3>
                            <p>You also have rights under this Policy. They include the rights to:</p>
                            <ul>
                                <li aria-level={1}><b>Ask what information we have about you. </b>You may ask us to explain what information we are keeping, why we are keeping it, and how we are using it.</li>
                                <li aria-level={1}><b>View your personal information. </b>You have the right to see the data we have stored about you. You can ensure the information is accurate and that we’re using the information in a legal manner.</li>
                                <li aria-level={1}><b>Update or correct the information.</b> If our data is incorrect or outdated, you may request a correction.</li>
                                <li aria-level={1}><b>Ask us to delete your data. </b>You may request deletion of your personal information if there is no legitimate need for us to retain it. You can also request the deletion when you exercise your right to object to processing.</li>
                                <li aria-level={1}><b>Object to processing.</b> This term refers to a situation where your personal circumstances make you unwilling or unable to agree to the retention and transmission of your personal information, even when we have a legitimate interest in keeping it. You may also use this right to limit the extent of our transmission and sharing of your personal data. For instance, you may object to our sharing of your personal data with specific corporate or government entities.</li>
                                <li aria-level={1}><b>Object to automated decision-making.</b> We use personal data to conduct automatic decision-making based on the personal profiles of our visitors. You may object to this use of your data. You may also ask us to delete your personal profile.</li>
                                <li aria-level={1}><b>Suspend our processing. </b>You may request a suspension of our use of your personal data for various reasons. For instance, if you want to review your personal data to ensure we’re using it legally or in compliance with this Policy, you can request a suspension of our use while you investigate.</li>
                                <li aria-level={1}><b>Request your information.</b> You may ask us to send your profile to you in a usable electronic format at any time. You may also ask us to transfer or transmit this profile to a third party of your choice. The data must be in a usable electronic format.</li>
                                <li aria-level={1}><b>Withdraw your consent. </b>At any time, you may ask us to stop collecting, processing, or transmitting your personal information. We will stop all collection, processing, and transmission upon request. We will stop or suspend this processing and collection for any of the purposes you originally agreed to, unless we have a legitimate, legal business interest in continuing to use your information.</li>
                            </ul>
                            <p>If you want to exercise any of these rights, please email us at <Link href="mailto:info@v4tech.com">info@v4tech.com</Link>. We typically respond in three to four weeks.</p>
                            <p>Please note the following:</p>
                            <ul>
                                <li aria-level={1}>We may request additional information to ensure your identity. We will do so to ensure you are the authorized owner of this information and have the right to view it or withdraw consent. This is a security measure that protects your personal information from unlawful disclosure to people who do not have the right to see it.</li>
                                <li aria-level={1}>We will respond to your exercise of your rights in accordance with the law. We will not comply with requests that are not legally valid.</li>
                            </ul>
                        </section>
                    </div>
                </div>
                <div className="content_section content_section_marker">
                    <div className="container ">
                        <section className="text_content image_shadow_ inside_content_section mt-40 "><h3>7. HOW WE PROTECT YOUR DATA</h3>
                            <ul>
                                <li aria-level={1}>We use the strictest security protocols to protect all personal data we retain and transmit. We use strict security measures to prevent fraudulent use of this data. Our storage and transmission techniques are designed to prevent the unlawful copying, destruction, alteration, or corruption of all personal profiles.</li>
                                <li aria-level={1}>We will store your personal data on our servers or the servers of our affiliates. V4TECH may share this data on cloud-based storage systems we exchange with our clients, third parties, service providers, subsidiaries, and other affiliates.</li>
                                <li aria-level={1}>To determine how long we will keep your information, we consider the type, amount, and nature of your personal information. Our protocols ensure differing levels of security measures that depend on the sensitivity of this data. We will only keep your data in storage and process it for as long as necessary to meet our business and other obligations.</li>
                                <li aria-level={1}>&nbsp;We may keep personal data when we are required to do so by legal and regulatory obligations. In the case of a legal or civil claim, we will use your personal data as needed to meet our legal and contractual obligations. Your data may help us process responses to charges and complaints. When we have met these legal and contractual obligations, we will destroy your data in a secure manner.</li>
                                <li aria-level={1}>We will retain your personal records when we are required to do so by law or regulatory requirements.</li>
                                <li aria-level={1}>We may use your personal data when necessary to exercise or defend our legal rights, to provide information related to an investigation, or to comply with other legal proceedings.</li>
                                <li aria-level={1}>We may process and transmit your personal information when necessary to take measures that prevent fraud and corruption.</li>
                            </ul>
                        </section>
                    </div>
                </div>
                <div className="content_section content_section_marker">
                    <div className="container ">
                        <section className="text_content image_shadow_ inside_content_section mt-40 mb-40"><h3>8. HOW WE USE COOKIES</h3>
                            <ul>
                                <li aria-level={1}>When you visit our Websites, V4TECH may place cookies and other codes on your browser or device. These codes, collectively known as “Cookies,” may also appear on unrelated websites you visit. Cookies help improve the user experience, streamline your online browsing, personalize your online profile and otherwise enhance your experience as a Website visitor. Cookies permit us to analyze our Websites, monitor our marketing approaches, understand our visitor patterns and improve the security of our Websites.</li>
                                <li aria-level={1}>Cookies are simple, text-based computer files. They do not retain personally identifying information, but they may contain links to stored personal profiles and other sources of personal data.</li>
                                <li aria-level={1}>Our websites use three different types of Cookies.<br />
                                    <b>Functional cookies</b>: These make it possible to navigate a website and use its features, including contact buttons and secured sections of the site. They enable account management and email subscriptions. Functional Cookies are the nuts and bolts of any website. You may recognize functional Cookies from extensions like SID, wp-settings-X and wp-settings-time-X.<br />
                                    <b>Analytical cookies</b>: Analytical Cookies allow website designers to collect and measure information about a site’s visitors. These Cookies detect and monitor how many visitors a Website gets, where they come from, which functions they use the most, and other details. You may recognize analytical Cookies by the extensions are 1_P JAR, _ga, and _gid.<br />
                                    <b>Third-party Cookies</b>: We use these Cookies to monitor your use of third-party websites that may be affiliated with us. They allow our third parties to monitor and analyze their ad responses, visitor information, user experience, and other information. Our third parties rely on these Cookies to monitor the security and functionality of their websites. You may recognize third-party Cookies from the extensions bcookie, sb, spin, wd, and xs.</li>
                                <li aria-level={1}>V4TECH may use persistent Cookies or session Cookies. A persistent Cookie remains on a web browser until it reaches a predetermined expiration date. Users may remove it before that date. A session cookie expires as soon as you end your engagement on a particular website. Many session cookies expire as soon as you close your browser.</li>
                            </ul>
                            <p>If you have questions about the Cookies we use and how long we retain them, please contact us.</p>
                            <h4>Controlling the Appearance and Retention of Cookies</h4>
                            <p>You can control the appearance of Cookies by changing settings on your browser. Chrome, Edge, Explorer, Firefox, and Safari all have settings that reduce or eliminate the appearance of Cookies.</p>
                            <p>You can adjust your browser settings to delete some of our Cookies. It is also possible to delete all Cookies on your browser and prevent new ones from attaching to your online browsing. Please note that deleting or blocking Cookies may frequently slow down your browser and prevent you from using a website’s functions.</p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;