import data from "../../content/about.json";
import React from 'react'
import {Link} from "react-router-dom";

function AboutArea () {
return (
    <section className="about-two-area pt-120">
        <div className="about-two__shape">
            <img src="assets/images/shape/about-two-shape.png" alt="shape" />
        </div>
        <div className="container">
            {
                data.about.map((data,i) => (
            <div key={i} className="row g-4">
                <div className="col-xl-6 wow fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
                    <div className="about-two__left-item">
                        <div className="dots">
                            <img className="sway_Y__animation" src="assets/images/shape/about-two-dot.png" alt="shape" />
                        </div>
                        <div className="shape-halper">
                            <img className="sway__animation" src="assets/images/shape/about-circle-helper.png" alt="shape" />
                        </div>
                        <div className="image big-image">
                            <img src={data.img} alt="image" />
                        </div>
                        <div className="image sm-image">
                            <img src={data.img2} alt="image" />
                        </div>
                        <div className="circle-shape">
                            <img className="animation__rotate" src="assets/images/shape/about-two-circle.png" alt="shape" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="section-header mb-20">
                        <h5 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <svg className="me-1" width={20} height={12} viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.75" y="0.75" width="18.5" height="10.5" rx="5.25" stroke="#3C72FC" strokeWidth="1.5" />
                                <mask id="path-2-inside-1_668_146" fill="white">
                                    <path d="M3 6C3 3.79086 4.79086 2 7 2H13C15.2091 2 17 3.79086 17 6C17 8.20914 15.2091 10 13 10H7C4.79086 10 3 8.20914 3 6Z" />
                                </mask>
                                <path d="M3 6C3 2.96243 5.46243 0.5 8.5 0.5H11.5C14.5376 0.5 17 2.96243 17 6C17 4.61929 15.2091 3.5 13 3.5H7C4.79086 3.5 3 4.61929 3 6ZM17 6C17 9.03757 14.5376 11.5 11.5 11.5H8.5C5.46243 11.5 3 9.03757 3 6C3 7.38071 4.79086 8.5 7 8.5H13C15.2091 8.5 17 7.38071 17 6ZM3 10V2V10ZM17 2V10V2Z" fill="#3C72FC" mask="url(#path-2-inside-1_668_146)" />
                            </svg>
                            {data.title}
                        </h5>
                        <h2 className="wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">{data.headline}</h2>
                        <p className="text-justify wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                            {data.description}
                        </p>
                    </div>
                    <div className="about-two__right-item wow fadeInDown" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <ul>
                            <li><i className="fa-solid fa-check" />{data.p1}</li>
                            <li><i className="fa-solid fa-check" />{data.p2}</li>
                        </ul>
                        <ul>
                            <li><i className="fa-solid fa-check" />{data.p3}</li>
                            <li><i className="fa-solid fa-check" />{data.p4}</li>
                        </ul>
                    </div>

                    {/*<div className="about__info mt-50 wow fadeInDown" data-wow-delay="400ms" data-wow-duration="1500ms">*/}
                    {/*    <Link to={data.link} className="btn-one">About Us <i className="fa-regular fa-arrow-right-long" /></Link>*/}
                    {/*    <img src={data.signature} alt="signature" />*/}
                    {/*</div>*/}
                </div>
                <div className="about-two__right-item wow fadeInDown" data-wow-delay="200ms" data-wow-duration="1500ms">
                    <h4 className="wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">{data.subtitle}</h4>
                    <ul>
                        <li><i className="fa-solid fa-check" />{data.point1}</li>
                        <li><i className="fa-solid fa-check" />{data.point2}</li>
                        <li><i className="fa-solid fa-check" />{data.point3}</li>
                        <li><i className="fa-solid fa-check" />{data.point4}</li>
                    </ul>
                    <ul>
                        <li><i className="fa-solid fa-check" />{data.point5}</li>
                        <li><i className="fa-solid fa-check" />{data.point6}</li>
                        <li><i className="fa-solid fa-check" />{data.point7}</li>
                    </ul>

                </div>
                <div className="section-header mb-20">
                    <h2 className="wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">{data.missiontitle}</h2>
                    <p className="text-justify wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                        {data.missiondescription}
                    </p>
                </div>
                <div className="section-header mb-20">
                    <h2 className="wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">{data.valuetitle}</h2>
                    <div className="about-two__right-item wow fadeInDown float-left mt-4" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <ul>
                            <li><i className="fa-solid fa-check" />{data.valuepoint1}</li>
                            <li><i className="fa-solid fa-check" />{data.valuepoint2}</li>
                        </ul>
                        <ul>
                            <li><i className="fa-solid fa-check" />{data.valuepoint3}</li>
                            <li><i className="fa-solid fa-check" />{data.valuepoint4}</li>
                        </ul>
                        <ul>
                            <li><i className="fa-solid fa-check" />{data.valuepoint5}</li>
                        </ul>
                    </div>
                </div>
            </div>

                ))
            }

        </div>
    </section>
)
};
export default AboutArea;