import BannerArea from "../../component/projects/bannerArea";
import ClientArea from "../../component/projects/clientArea";

function Index() {
    return (
        <>
            <BannerArea/>
            <ClientArea/>
        </>
    );
}

export default Index;