import * as React from "react";
const FlutterSvg = (props) => (
    <svg
        height={40}
        viewBox="0 0 100.100 100.801"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#0056f3">
            <path d="m48.75 95.97-25.91-25.74 14.32-14.57 40.39 40.31z" fill="#02539a"
            />
            <g fill="#45d1fd">
                <path d="m22.52 70.25 25.68-25.68h28.87l-39.95 39.95z" fillOpacity={0.85}/>
                <path d="m.29 47.85 14.58 14.57 62.2-62.2h-29.02z" />
            </g>
        </g>
    </svg>
);
export default FlutterSvg;
