import BannerArea from "../../component/articles/bannerArea";
import ArticlesForm from "../../component/articles/ArticlesForm";

function Index() {
    return (
        <>
            <BannerArea/>
            <ArticlesForm/>
        </>
    );
}

export default Index;