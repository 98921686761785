import { Link } from "react-router-dom";
import ServCard from "../component/Child/mega/servCard";
import {useLocation} from 'react-router-dom';


function Header () {
    const location = useLocation()
    return(
        <header className="header-area header-two-area">
            <div className="container header__container">
                <div className="header__main">
                    <Link className="logo" to="/">
                        <img src="assets/images/logo/logo-light.svg" alt="logo"/>
                    </Link>
                    <div className="main-menu">
                        <nav>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li className="has-megamenu"><Link to="/services">Our Services</Link>
                                    <ul className="sub-menu mega-menu menu-image">
                                        <li>
                                            <ServCard/>
                                        </li>
                                    </ul>
                                </li>
                                {/*<li><Link to="/technologies" onClick={() => {setTimeout(() => {window.location.reload()}, 0)}}>Technologies</Link>*/}
                                {/*    <ul className="sub-menu">*/}
                                {/*        <li><a href="#" onClick={() => {setTimeout(() => {window.location.reload()}, 0)}}>Ecommerce Technologies</a></li>*/}
                                {/*        <li><a href="#" onClick={() => {setTimeout(() => {window.location.reload()}, 0)}}>ERP Technologies</a></li>*/}
                                {/*        <li><a href="#" onClick={() => {setTimeout(() => {window.location.reload()}, 0)}}>CMS Technologies</a></li>*/}
                                {/*    </ul>*/}
                                {/*</li>*/}
                                {/*<li><Link to="/projects">Our Clients</Link></li>*/}
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="d-none d-xl-flex gap-4">
                        <>
                        {location.pathname !=="/contact" &&
                        <Link className="btn-one" to="/contact">Let's Connect <i className="fa-regular fa-arrow-right-long"/></Link>
                        }
                        </>
                        <div className="about-three__left-item d-flex flex-wrap gap-2 align-items-center">
                            <div className="about-call-icon">

                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="none" viewBox="0 0 26 26">
                      <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                         clipPath="url(#clip0_918_1337)">
                        <path
                            d="M5.417 4.333H9.75l2.167 5.417-2.709 1.625a11.916 11.916 0 005.417 5.417l1.625-2.709 5.417 2.167v4.333A2.167 2.167 0 0119.5 22.75 17.333 17.333 0 013.25 6.5a2.167 2.167 0 012.167-2.167zM16.25 7.583a2.167 2.167 0 012.167 2.167M16.25 3.25a6.5 6.5 0 016.5 6.5"/>
                      </g>
                    </svg>
                  </span>
                            </div>
                            <div className="info">
                                <span className="sm-font fw-600 text-white">Call Us Now</span>
                                <h5><Link to="tel:00966564682252" className="text-white">00966 50 337 4840</Link></h5>
                            </div>
                        </div>
                    </div>
                    <div className="bars d-block d-lg-none">
                        <i id="openButton" className="fa-solid fa-bars"/>
                    </div>
                </div>
            </div>
        </header>
    )
};
export default Header;