import BannerArea from '../../component/about/bannerArea';
import BrandsArea from "../../component/home/brandsArea";
import AboutArea from "../../component/about/aboutArea";
import CaseArea from "../../component/about/caseArea";
import TeamArea from "../../component/home/teamArea";
import PionerArea from "../../component/about/pionerArea";
import AtestmonialArea from "../../component/about/atestmonialArea";
function Index() {
    return (
        <>
            <BannerArea/>
            <AboutArea/>
            <PionerArea/>
            <BrandsArea/>
            <CaseArea/>
            {/*<AtestmonialArea/>*/}
            {/*<TeamArea/>*/}
        </>
    );
}

export default Index;