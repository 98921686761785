import ServCard from "../Child/child/servCard";

const ServicesArea = () => {
return (
    <section className="service-inner-area pt-120 pb-120">
        <div className="container">
            <div className="row g-4">
                <ServCard/>
            </div>
        </div>
    </section>
);
};
export default ServicesArea;