import {Link} from "react-router-dom";
import data from "../../content/clients.json";
import React from "react";

function BannerArea () {
return (
    <>
        {
            data.banner.map((data,i) => (
    <section key={i} className="banner__inner-page bg-image pt-180 pb-180 bg-image" style={{backgroundImage: `url(${data.img})`}}>
        <div className="shape2 wow slideInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
            <img src="assets/images/banner/inner-banner-shape2.png" alt="shape" />
        </div>
        <div className="shape1 wow slideInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
            <img src="assets/images/banner/inner-banner-shape1.png" alt="shape" />
        </div>
        <div className="shape3 wow slideInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
            <img className="sway__animationX" src="assets/images/banner/inner-banner-shape3.png" alt="shape" />
        </div>
        <div className="container">
            <h2 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">{data.title}</h2>
            <div className="breadcrumb-list wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                <Link to={data.bridelink}>{data.bridename}</Link><span><i className="fa-regular fa-angles-right mx-2" />{data.location}</span>
            </div>
        </div>
    </section>
            ))
        }
    </>
)
}
export default BannerArea;