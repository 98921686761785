import data from "../../content/home.json";
import React from 'react'


function ChooseCard () {
    return (
        <div className="row g-4 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
            {
                data.data.choose[0].chooseus.map((data,i) => (
            <div key={i} className="col-md-6">
                <div className="about__right-item">
                    <div className="icon">
                        <img src={data.img} alt="icon" />
                    </div>
                    <div className="content">
                        <h4 className="mb-1">{data.title}</h4>
                        <p>{data.description}</p>
                    </div>
                </div>
            </div>
                ))
            }
        </div>
    )
};
export default ChooseCard;