import data from "../../content/home.json";

function FaqArea () {
return (
    <section className="faq-area sub-bg pt-120 pb-120">
        <div className="faq__shape">
            <img className="sway__animationX" src="assets/images/shape/faq-shape.png" alt="shape" />
        </div>
        <div className="container">

            <div  className="row g-4">
                {
                    data.data.faq.map((data,i) => (
                <div key={i} className="col-lg-5 pe-2 pe-lg-5">
                    <div className="faq__image image wow fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div className="faq__line sway__animation">
                            <img src="assets/images/shape/faq-line.png" alt="image" />
                        </div>
                        <img src={data.img} alt="image" />
                    </div>

                </div>
                    ))
                }
                <div className="col-lg-7 mt-60">
                    {
                        data.data.faq.map((data,i) => (
                    <div key={i} className="section-header mb-40">
                        <h5 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <svg className="me-1" width={20} height={12} viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.75" y="0.747803" width="18.5" height="10.5" rx="5.25" stroke="#3C72FC" strokeWidth="1.5" />
                                <mask id="path-2-inside-1_1120_294" fill="white">
                                    <path d="M3 5.9978C3 3.78866 4.79086 1.9978 7 1.9978H13C15.2091 1.9978 17 3.78866 17 5.9978C17 8.20694 15.2091 9.9978 13 9.9978H7C4.79086 9.9978 3 8.20694 3 5.9978Z" />
                                </mask>
                                <path d="M3 5.9978C3 2.96024 5.46243 0.497803 8.5 0.497803H11.5C14.5376 0.497803 17 2.96024 17 5.9978C17 4.61709 15.2091 3.4978 13 3.4978H7C4.79086 3.4978 3 4.61709 3 5.9978ZM17 5.9978C17 9.03537 14.5376 11.4978 11.5 11.4978H8.5C5.46243 11.4978 3 9.03537 3 5.9978C3 7.37851 4.79086 8.4978 7 8.4978H13C15.2091 8.4978 17 7.37851 17 5.9978ZM3 9.9978V1.9978V9.9978ZM17 1.9978V9.9978V1.9978Z" fill="#3C72FC" mask="url(#path-2-inside-1_1120_294)" />
                            </svg>
                            {data.title}
                        </h5>
                        <h2 className="wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">{data.headline}</h2>
                    </div>
                        ))
                    }
                    <div className="faq__item">

                        <div className="accordion" id="accordionExample">
                            {
                                data.data.faq[0].question1.map((data,i) => (
                            <div key={i} className="accordion-item wow fadeInDown shadow border-none" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        {data.question}
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p className="lh-base text-break text-justify">{data.answer}
                                        </p>
                                    </div>
                                </div>
                            </div>
                                ))
                            }
                            {
                                data.data.faq[0].question2.map((data,i) => {
                                    return (
                                        <div key={i} className="accordion-item wow fadeInDown shadow border-none" data-wow-delay="200ms" data-wow-duration="1500ms">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    {data.question}
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="about-two__right-item accordion-body">
                                                    <p className="font-weight-bold text-capitalize">{data.answer}
                                                    </p>
                                                    <>

                                                        <ul className="list-group list-group-flush ">
                                                            {
                                                                data?.list.map((info,i) => (
                                                                    <li key={i} className="list-group-item mb-0 px-0"><i className="fa-solid fa-check" /> {info.point}</li>
                                                                ))
                                                            }
                                                        </ul>

                                                    </>
                                                    <p className="lh-base text-break text-justify">{data.answer2}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                })
                            }
                            {
                                data.data.faq[0].question3.map((data,i) => (
                            <div key={i} className="accordion-item wow fadeInDown shadow border-none" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <h2 className="accordion-header" id="headingthree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded="false" aria-controls="collapsethree">
                                        {data.question}
                                    </button>
                                </h2>
                                <div id="collapsethree" className="accordion-collapse collapse" aria-labelledby="headingthree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p className="lh-base text-break text-justify">{data.answer}
                                        </p>
                                    </div>
                                </div>
                            </div>
                                ))
                            }
                            {
                                data.data.faq[0].question4.map((data,i) => (
                            <div key={i} className="accordion-item wow fadeInDown shadow border-none" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        {data.question}
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p className="lh-base text-break text-justify">{data.answer}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            ))
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </section>
)
};
export default FaqArea;